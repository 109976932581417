import React from 'react'
import "./loader.css"
const loader = () => {
  return (
    <div className='loader-container' >
    <span class="loader"></span>
    </div>
  )
}

export default loader
